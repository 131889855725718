<template>
    <div class="grid">
        <div class="col-12 md:col-12">
            <div class="card p-fluid">
                <h5>{{ complexidade.id ? 'Atualizar Complexidade' : 'Nova Complexidade' }}</h5>
                <div class="field col-9">
                    <label class="required">Título</label>
                    <InputText type="text" v-model="complexidade.title" />
                </div>
                <div class="field-checkbox col-10">
                    <Checkbox id="binary" v-model="complexidade.active" :binary="true" />
                    <label for="binary">Ativo</label>
                </div>
            </div>
            <Button label="Cancelar" @click.stop="cancelar" icon="pi pi-times-circle" class="mr-2 p-button-secondary"></Button>
            <Button label="Salvar" @click.stop="inserir" icon="pi pi-check-circle" :disabled="!complexidade.title"></Button>
        </div>
    </div>
</template>
<script>
import Services from './service';

export default {
    data() {
        return {
            complexidade: {
                active: true,
                title: null,
            },
        };
    },
    mounted() {
        if (this.$route.params.id) {
            Services.obterPorId(this.$route.params.id).then((response) => {
                if (response?.success) {
                    this.complexidade = response.data;
                } else {
                    this.complexidade = null;
                }
            });
        }
    },

    methods: {
        inserir() {
            this.$store.dispatch('addRequest');

            if (this.complexidade.id) {
                Services.atualizar(this.complexidade.id, this.complexidade).then((response) => {
                    this.respostaSalvar(response, true);
                });
            } else {
                Services.inserir(this.complexidade).then((response) => {
                    this.respostaSalvar(response, false);
                });
            }
        },
        respostaSalvar(response, edicao) {
            if (response?.success) {
                this.$toast.add({
                    severity: 'success',
                    summary: 'Complexidade',
                    detail: `Complexidade ${edicao ? 'editada' : 'inserida'} com sucesso`,
                    life: 3000,
                });

                this.cancelar();
            } else {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Erro',
                    detail: response.errors[0],
                    life: 10000,
                });
            }
            this.$store.dispatch('removeRequest');
        },
        cancelar() {
            this.$router.push({
                name: `Complexidade`,
            });
        },
    },
};
</script>
