import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_SOL}${process.env.VUE_APP_API_PATH}/sol`;
const urlBase = `${api}/complexidades`;

export default {
    obterTodos(active) {
        active == null ? active = '' : active
        return axiosJwt.get(`${urlBase}?active=${active}`);
    },
    obterPorId(id) {
        return axiosJwt.get(`${urlBase}/${id}`);
    },
    excluir(id) {
        return axiosJwt.delete(`${urlBase}/${id}`);
    },
    inserir(complexidade) {
        return axiosJwt.post(`${urlBase}`, complexidade);
    },
    atualizar(id, complexidade) {
        return axiosJwt.put(`${urlBase}/${id}`, complexidade);
    },
  
};